export const commonPageEventActions = {
    searchSection: {
        searchBox: {
            SEARCH: 'click_search',
            CLICK_SEARCH: 'searchbox_click_search',
        },
    },
}

export const landingPageEventActions = {
    searchSection: {
        searchBox: {
            ARRIVAL_DATE: 'add_arrvial',
            DEPARTURE_DATE: 'add_departure',
            LOCATION: 'add_destination',
            PERSONS_COUNT: 'click_pers',
        },
        BREADCRUMB: 'click_breadcrumb',
    },
    headerSection: {
        RENTAL: 'click_rental',
        WISHLIST: 'click_wishlist',
    },
    newsLetterSection: {
        REGISTER: 'click_newsletter_subscription',
    },
    listingCardSection: {
        TOP_INSERAT: 'click_topinserat',
        WISHLIST_POP_UP: 'popupWishlist',
    },
}

export const searchResultListingsPageEventActions = {
    listViewPageSpecific: {
        TO_BE_ADDED: 'to_be_added',
    },
    mapViewPageSpecific: {
        mapSection: {
            MAP: 'do_mapaction',
        },
    },
    LISTINGS: 'click_resultlist_snippet',
    tileViewPageSpecific: {
        TO_BE_ADDED: 'to_be_added',
    },
    filterSection: {
        FILTER_CLICK: 'result_filter_click_filteroption',
    },
}

export const eventPageActions = {
    landingPageActions: {
        SEARCH_BUTTON: 'click_bk_search',
        MOST_POPULAR_DESTINATION: 'click_bk_mostpopular_by_dest',
        MOST_POPULAR_CATEGORY: 'click_bk_mostpopular',
    },
    searchResultPageActions: {
        SEARCH_RESULT_SNIPPET: 'click_bk_events_srl_snippet',
    },
    detailPageActions: {
        BOOKING_BUTTON: 'click_bk_events_booking',
    },
}
